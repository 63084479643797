export const ClientReviewData = [
    {
        image:"/assets/images/KH/KH1.jpg",
        title:"Macaron Delight",
        desc:"Fresh Garden ra mắt bộ sưu tập bánh kem mới cũng được hơn một tháng rồi,mình đã có cơ hội thử loại bánh kem cốt chiffon mới toanh. Cốt mềm,thơm và vị thì tuyệt vời luôn nha."
    },
    {
        image:"/assets/images/KH/KH2.jpg",
        title:"Macaron Delight",
        desc:"Fresh Garden ra mắt bộ sưu tập bánh kem mới cũng được hơn một tháng rồi,mình đã có cơ hội thử loại bánh kem cốt chiffon mới toanh. Cốt mềm,thơm và vị thì tuyệt vời luôn nha."
    },
    {
        image:"/assets/images/KH/KH3.jpg",
        title:"Macaron Delight",
        desc:"Fresh Garden ra mắt bộ sưu tập bánh kem mới cũng được hơn một tháng rồi,mình đã có cơ hội thử loại bánh kem cốt chiffon mới toanh. Cốt mềm,thơm và vị thì tuyệt vời luôn nha."
    },
    {
        image:"/assets/images/KH/KH4.jpg",
        title:"Macaron Delight",
        desc:"Fresh Garden ra mắt bộ sưu tập bánh kem mới cũng được hơn một tháng rồi,mình đã có cơ hội thử loại bánh kem cốt chiffon mới toanh. Cốt mềm,thơm và vị thì tuyệt vời luôn nha."
    },
]