export const MainCarouselData = [
    {
        image:"/assets/images/Banner/Banner1.jpg",
        path:""
    },
    {
        image:"/assets/images/Banner/Banner2.jpg",
        path:""
    },
    {
        image:"/assets/images/Banner/Banner3.jpg",
        path:""
    },
]